@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
    --primary: #ffffff;
    --gray: #756388;
    --light: #f4f7ff;
    --highlight: #b7bcf3;
    --bg: #ffffff;
    --overlays: #1F2225;
    --border: #b4b4b4;
    --text: #232b2b;
}

body {
    font-family: 'Source Code Pro', monospace;
    background-color: var(--bg);
    color: var(--text);
    overflow: auto;
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

.vstack a {
    color: var(--text);
    font-weight: 400;
}

.navbar-override {
    position: absolute;
    top: 20px;
    width: 100%;
    background: transparent;
}

.navbar-override-content {
    position: relative;
    top: 0px;
    width: 100%;
    background-color: var(--overlays);
}

.top-branding-logo {
    margin-right: 5px;
}

.navbar-brand {
    line-height: 45px;
    color: var(--primary);
}

.navbar-brand:hover {
    color: var(--primary);
}

.nav-link,
.nav-link a {
    color: var(--primary);
    text-decoration: none;
    font-size: 20px;
    /* font-weight: 100; */
}

.nav-link:hover,
.nav-link a:hover {
    color: var(--gray);
}

.nav-link-tab a {
    color: #6f6f6f;
}

.nav-link-tab.activated a {
    color: var(--primary);
    font-weight: 600;
    text-decoration: underline;
}

.dropdown-item a {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    color: var(--text);
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.navbar-expand-lg .navbar-nav .nav-link {
    color: var(--primary);
}

.dropdown-toggle::after {
    display: none;
    vertical-align: 0.14em;
}

.top-login-links {
    width: 141px;
}

.hero-container {
    height: 100vh;
    width: 100vw;

    background: linear-gradient(0deg, #565F6B, #1F2225);
    color: #e4e4e4;
}

.hero-header {
    font-size: 34px;
    font-weight: 400 !important;
}

.hero-sub-header {
    font-weight: 400;
}

.hero-headline {
    width: 100%;
    position: absolute;
    top: 40%;
}

.empty-padding {
    height: 10px;
}

.row-content {
    padding: 0px 0px 30px 0px;
}

.block-underlined {
    border-bottom: 1px dotted var(--border);
}

.block-card-style{
    padding: 10px;
    border: 1px solid #000;
}

.footer {
    z-index: 2;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: var(--overlays);

    color: #888;
    font-weight: 100;
}

.footer a {
    text-decoration: none;
}


/* .login-form-container {
    border: 1px solid var(--border);
    background-color: #ffffff;
} */

.login-form-contents {
    width: 80%;
    display: block;
    margin: auto;
}

.login-form-contents h1,
.login-form-contents p {
    text-align: center;
    display: block;
    margin: auto;
}

.form-text-centered,
.text-muted {
    text-align: center;
    display: block;
}

.centered-div-container {
    margin: auto;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: 100;
}

.logo-login-register {
    width: 75px;
    display: block;
    margin: auto;
}

.text-justify {
    text-align: justify;
}

.image-container img {
    width: 100%;
    display: flex;
    margin: auto;
    border: 1px solid var(--border);
}

.image-container-borderless img {
    width: 100%;
    display: flex;
    margin: auto;
}

.image-container-w30 img {
    width: 30%;
    display: flex;
    margin: auto;
}

/* Accordion stuff */
.accordion-item {
    border: none;
    background: transparent;
}

.accordion-button {
    padding: 10px;
    border-radius: 5px;
    box-shadow: none;
    background: rgb(223, 229, 244);
    margin: 5px 0px;
}

.accordion-button:not(.collapsed) {
    padding: 10px;
    border-radius: 5px;
    box-shadow: none;
    background: rgb(223, 229, 244);
}

.accordion-body {
    padding: 1% 0px;
}

.accordion .vstack {
    padding: 5px 10px;
    margin: 10px 0px;
    border-radius: 5px;
}

.accordion-button::after {
    transform: rotate(-90deg);
}

.accordion-button:not(.collapsed)::after {
    transform: none;
}

.code {
    display: block;
    font-family: monospace;
    white-space: pre;
    margin: 1em 0;
    color: #6b5e8e;
}